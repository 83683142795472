<template>
  <div>
  </div>
</template>

<script>
  import AuthService from "@/services/auth.helper"

  export default {
    created() {
      let role = this.$store.state.user ? parseInt(this.$store.state.user.primary_role_id) : ''
      if([1,3].includes(role)) {
        // console.log("trying to go top instructor home")
        this.$router.push('/instructor/home')
        return
      }
      if([4].includes(role)) {
        this.$router.push('/school/home')
        return
      }
      if([5].includes(role)) {
        this.$router.push('/manager')
        return
      }
      if([6].includes(role)) {
        this.$router.push('/instructor/courses')
        return
      }
      if([2].includes(role)) {
        this.$router.push('/student/home')
        return
      }
      AuthService.logout()
      this.$router.push('/login')
    }
  }
</script>

<style scoped>

</style>
